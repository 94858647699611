import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { CompanyLogoQuery } from "../../types/graphql-types";

const CompanyLogo = () => {
  const data = useStaticQuery<CompanyLogoQuery>(graphql`
    query CompanyLogo {
      logos: datoCmsConfig {
        logoIcon {
          url
        }
        logoType {
          url
        }
      }
      site: datoCmsSite {
        globalSeo {
          siteName
        }
      }
    }
  `);
  const siteName = data.site!.globalSeo!.siteName!;
  const logoType = data!.logos!.logoType!.url!;
  const logoIcon = data!.logos!.logoIcon!.url!;
  return (
    <>
      <img src={logoIcon} alt={siteName} className="inline h-8 mr-1" />
      <img src={logoType} alt={siteName} className="inline h-8" />
    </>
  );
};

export default CompanyLogo;
