import React from "react";
import { Helmet, MetaProps } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { SeoQueryQuery } from "../../types/graphql-types";

type SEOProps = {
  readonly title?: string;
  readonly lang?: string;
  readonly description?: string;
  readonly image?: string;
  readonly meta?: MetaProps[];
};

const SEO: React.FC<SEOProps> = ({
  title,
  lang = "ja",
  description,
  image,
  meta = [],
}) => {
  const data = useStaticQuery<SeoQueryQuery>(graphql`
    query SEOQuery {
      site: datoCmsSite(locale: { eq: "ja" }) {
        globalSeo {
          twitterAccount
          titleSuffix
          fallbackSeo {
            title
            description
            twitterCard
            image {
              url
            }
          }
        }
      }
    }
  `);
  const site = data.site!;
  const globalSeo = site.globalSeo!;
  if (title) {
    title = title + globalSeo.titleSuffix!;
  } else {
    title = globalSeo.fallbackSeo!.title!;
  }
  description = description ?? globalSeo.fallbackSeo!.description!;
  image = image ?? globalSeo.fallbackSeo!.image!.url!;
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: image,
        },
        {
          name: `twitter:card`,
          content: globalSeo.fallbackSeo!.twitterCard!,
        },
        {
          name: `twitter:creator`,
          content: globalSeo.twitterAccount!,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        ...meta,
      ]}
    />
  );
};

export default SEO;
