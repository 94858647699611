import classNames from "classnames";
import React from "react";
import Footer from "../topPage/components/footer";
import { Favicon } from "./favicon";
import { NavigationBar } from "./navigationBar";

type LayoutProps = {
  readonly noPaddingX?: boolean;
};

const Layout: React.FC<LayoutProps> = ({ children, noPaddingX = false }) => {
  return (
    <>
      <Favicon />
      <NavigationBar onlyLogo />
      <main
        className={classNames(
          "container mx-auto py-4",
          noPaddingX ? null : "px-4 sm:px-6"
        )}
      >
        {children}
      </main>
      <div className="py-4">{""}</div>
      <Footer />
    </>
  );
};

export default Layout;
