import classNames from "classnames";
import { graphql, Link, PageProps } from "gatsby";
import { PageContext } from "gatsby-awesome-pagination";
import React from "react";
import { PaginatedDatoCmsPostsQuery } from "../../../types/graphql-types";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

type Props = PageProps<PaginatedDatoCmsPostsQuery, PageContext>;

const PostIndexPage: React.FC<Props> = ({
  data: {
    allDatoCmsPost: { edges },
  },
  pageContext: { previousPagePath, nextPagePath },
}) => {
  const getColor = (category: string): string => {
    const colors = [
      "bg-red-400",
      "bg-orange-400",
      "bg-yellow-400",
      "bg-green-400",
      "bg-teal-400",
      "bg-blue-400",
      "bg-indigo-400",
      "bg-purple-400",
      "bg-pink-400",
    ];
    return colors[category.length % colors.length];
  };
  return (
    <Layout>
      <SEO title="ブログ" />
      <div className="mx-auto max-w-xl">
        {edges.map(({ node: post }) => (
          <div key={post.slug!} className="mb-8">
            <div>
              <span
                className={classNames(
                  getColor(post.category!),
                  "text-white text-xs rounded font-semibold"
                )}
                style={{ padding: "0.125rem 0.5rem" }}
              >
                {post.category}
              </span>
            </div>
            <div className="mt-2">
              <Link
                to={post.slug!}
                className="md:text-xl font-semibold hover:underline"
              >
                {post.title}
              </Link>
            </div>
            <div className="mt-2 text-sm text-gray-800 flex">
              <span className="inline-block">{post.date}</span>
              <span className="inline-block ml-4 flex">
                <img
                  src={post.author!.image!.fixed!.src}
                  srcSet={post.author!.image!.fixed!.srcSet}
                  className="inline"
                  style={{ width: 24, height: 24 }}
                />
                <span className="inline-block ml-1">{post.author!.name}</span>
              </span>
            </div>
          </div>
        ))}
        <div className="flex justify-between">
          <div>
            {previousPagePath && (
              <Link
                to={previousPagePath}
                className="border border-gray-500 hover:border-blue-500 text-gray-500 hover:text-blue-500 rounded px-2 py-1 inline-block text-sm"
              >
                ←前のページ
              </Link>
            )}
          </div>
          <div>
            {nextPagePath && (
              <Link
                to={nextPagePath}
                className="border border-gray-500 hover:border-blue-500 text-gray-500 hover:text-blue-500 rounded px-2 py-1 inline-block text-sm"
              >
                次のページ→
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

// noinspection JSUnusedGlobalSymbols
export default PostIndexPage;

// noinspection JSUnusedGlobalSymbols
export const pageQuery = graphql`
  query PaginatedDatoCmsPosts($skip: Int!, $limit: Int!) {
    allDatoCmsPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          slug
          title
          date(formatString: "LL", locale: "ja")
          category
          author {
            name
            image {
              fixed(
                height: 24
                width: 24
                imgixParams: { fm: "png", mask: "ellipse" }
              ) {
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`;
