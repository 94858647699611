import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";
import CompanyLogo from "./companyLogo";

export const NavigationBar: React.FC<{
  onlyLogo?: boolean;
}> = ({ onlyLogo = false }) => {
  const links = [
    { children: "Service", href: "#" },
    { children: "About", href: "#" },
    { children: "Blog", href: "#" },
  ];
  return (
    <nav className="container mx-auto py-8 px-6">
      <div
        className={classNames(
          "w-full h-full flex flex-wrap items-center",
          onlyLogo ? "justify-center" : "justify-between"
        )}
      >
        <Link to="/">
          <CompanyLogo />
        </Link>
        {!onlyLogo && (
          <div>
            {links.map((link) => (
              <a
                key={link.children}
                {...link}
                className="font-bold inline-block py-2 px-4 hover:text-gray-700"
              />
            ))}
          </div>
        )}
      </div>
    </nav>
  );
};
