import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { FaviconsQuery } from "../../types/graphql-types";

export type FaviconProps = {};

export const Favicon: React.FC<FaviconProps> = () => {
  const data = useStaticQuery<FaviconsQuery>(graphql`
    query Favicons {
      favicons: datoCmsConfig {
        favicon {
          url
        }
        fallbackFavicon {
          url
        }
      }
    }
  `);
  return (
    <Helmet
      link={[
        {
          rel: "icon",
          href: data.favicons!.favicon!.url!,
          type: "image/svg+xml",
        },
        {
          rel: "icon alternate",
          href: data.favicons!.fallbackFavicon!.url!,
          type: "image/png",
        },
      ]}
    />
  );
};
